<template>
	<div class="integration">
		<PageHeader title="Integrations"/>
		<section class="integration-list">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 col-md-4 col-sm-6">
						<!--Mailchimp-->
						<FlipCard ref="mailchimp"
								  type="mailchimp"
								  description="Send new leads straight to your mailing list at MailChimp.">
							<template slot="front">
								<template
										v-if="integration.mailchimpListId === null || integration.mailchimpListId === '' || integration.mailchimpEndPoint==null">
									<button class="btn btn-primary" @click="redirectToMailchimp">Enable</button>

									<div>
										<a class="card-link"
										   href="https://siteauditor.com/growthrobotics-mailchimp"
										   target="_blank">Need Help?</a>
									</div>

								</template>
								<template v-else>
									<a class="rotate-btn">
										<button @click="showPopup('MailChimp')" class="btn btn-danger">
											Revoke Access
										</button>
									</a>
									<div>
										<a class="card-link"
										   href="https://siteauditor.com/growthrobotics-mailchimp"
										   target="_blank">Need Help?</a>
									</div>
								</template>

							</template>
							<template slot="back">
								<Select :key="key" :options="mailchimpOptions" v-model="selectedOption"
										:selectedOption="selectedOption"/>
								<button class="btn btn-primary" @click="saveMailChimp">Save</button>

							</template>
						</FlipCard>

					</div>

					<!--Zapier -->
					<div class="col-lg-3 col-md-4 col-sm-6">
						<FlipCard ref="zapier"
								  type="zapier"
								  description="Send new leads straight to Zapier.">
							<template slot="front">
								<template>
									<a :href="zapierEndpoint"
									   class="btn btn-primary" target="_blank"> Make a Zap</a>
									<div>
										<a class="card-link"
										   href="https://siteauditor.com/growthrobotics-zapier"
										   target="_blank">Need Help?</a>
									</div>
								</template>
							</template>
						</FlipCard>
					</div>
					<!--Hubspot-->
					<div class="col-lg-3 col-md-4 col-sm-6">
						<FlipCard ref="hubspot"
								  type="hubspot"
								  description="Send new leads straight to Hubspot.">
							<template slot="front">
								<template v-if="hubspotLoading">
									<span>Loading...</span>
								</template>
								<template v-else>
									<template
											v-if="integration.hubspotRefreshToken === null || integration.hubspotRefreshToken === ''">
										<button class="btn btn-primary" @click="redirectToHubSpot">Enable</button>
										<div>
											<a class="card-link"
											   href="https://siteauditor.com/siteauditor-hubspot"
											   target="_blank">Need Help?</a>
										</div>
									</template>
									<template v-else>
										<a class="rotate-btn">
											<button @click="showPopup('HubSpot')" class="btn btn-danger">
												Revoke Access
											</button>
										</a>
										<div>
											<a class="card-link"
											   href="https://siteauditor.com/siteauditor-hubspot"
											   target="_blank">Need Help?</a>
										</div>
									</template>
								</template>
							</template>
						</FlipCard>
					</div>

<!--					Wordpress-->
					<div class="col-lg-3 col-md-4 col-sm-6">
						<FlipCard ref="Wordpress"
								  type="wordpress"
								  title="Wordpress Plugin"
								  description="Push SiteAudit.com forms on all WordPress blogs and web pages in just one click.">
							<template slot="front">
								<template>
									<a class="btn btn-primary" :href="wordpressPluginUrl" download="">Download</a>

									<div>
										&nbsp;
									</div>
								</template>

							</template>
						</FlipCard>
					</div>
<!--					Chrome Extention-->
					<div class="col-lg-3 col-md-4 col-sm-6">
						<FlipCard ref="chrome-extension"
								  type="chrome"
								  title="Chrome Extension"
								  description="Get website Audit Report in just one click.">
							<template slot="front">
								<template>
									<a class="btn btn-primary"
									   href="https://chrome.google.com/webstore/detail/seo-audit-tool/onhkfjmnklnlncgpkipbjjhmjnpdpmgk"
									   target="_blank">
										Add Extension
									</a>
									<div>
										&nbsp;
									</div>

								</template>

							</template>
						</FlipCard>
					</div>

					<div class="col-lg-3 col-md-4 col-sm-6">
						<FlipCard ref="conversion"
								  type="conversion"
								  title="Conversion Tracking"
								  description="Track conversions using any JavaScript or HTML code snippet.">
							<template slot="front">
								<template v-if="integration.viewAnalytics === 0">
									<button class="btn btn-primary" @click="openConversionPopup">Integrate</button>
									<div>
										&nbsp;
									</div>
								</template>
								<template v-else>
									<a class="rotate-btn">
										<button class="btn btn-primary" @click="openConversionPopup">Edit
											Integration
										</button>
										<button @click="showPopup('Conversion Tracking')"
												class="btn btn-danger">
											Revoke Access
										</button>
									</a>
								</template>

							</template>
						</FlipCard>
					</div>

				</div>
				<Modal modal-id="revoke-access-modal"
					   submit-button-text="YES"
					   cancel-button-text="NO"
					   size="s"
					   :hideTitle="true"
					   :handleOk="revokeAccess">
					<div>
						<h2>Are you sure?</h2>

						<h6>You want to Revoke {{this.revokeType}} Access</h6>
					</div>
				</Modal>
				<Modal modalId='conversion-tracking-modal'
					   title="Conversion Tracking"
					   size="m"
					   :handle-ok="checkConversionTracking"
					   submit-button-text="Save">

					<h5>HTML Code</h5>
					<p>You can grab your Facebook Pixel Code, Google Remarketing Code or other tracking code and put it here.</p>

					<div class="form-group">

						<textarea class="form-control"
								  id="form-analyticsScript"
								  v-model="analyticsScriptInput"
								  rows="8"
						></textarea>
						<span class="invalid-feedback" style="display: block">{{scriptError}}</span>
					</div>
				</Modal>
			</div>
		</section>
	</div>
</template>

<script>
    import PageHeader from "../../../../shared/components/PageHeader";
    import FlipCard from "../../../../shared/components/FlipCard";
    import Select from "../../../../shared/components/Select";
    import Modal from "../../../../shared/components/Modal";
    import {extend, validate} from "vee-validate";

    extend('script', {
        validate(value) {
            const scriptString = value.toLowerCase();
            let startTag = '<script';
            let endTag = '</' + 'script>';

            return scriptString.indexOf(startTag) !== -1
                && scriptString.indexOf(endTag) !== -1
                && scriptString.indexOf(startTag) < scriptString.indexOf(endTag);

        },
        message: 'HTML code should contain open and closing `script` tags',
    });

    const MAILCHIMP_ENDPOINT = 'https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=';
    const HUBSPOT_ENDPOINT = 'http://app.hubspot.com/oauth/authorize?client_id=';


    export default {
        name: "integrations",
        components: {Modal, Select, FlipCard, PageHeader},
        data() {
            return {
                wordpressPluginUrl: process.env.VUE_APP_WORDPRESS_PLUGIN_URL,
                key: '',
                revokeType: '',
                scriptError: '',
                mailchimpOptions: [],
                selectedOption: '',
                hubspotLoading: false,
                analyticsScriptInput: '',
                integration: {
                    mailchimpToken: null,
                    mailchimpListId: "",
                    mailchimpEndPoint: null,
                    hubspotRefreshToken: null,
                    viewAnalytics: 0,
                    analyticsScript: '',
                },


                allowedDownloadPerEmail: null,
                unlimitedDownloadEmails: null,
                status: null,
                leadSecretKey: null,
                leadAccessKey: null,
                usersId: null,
                type: "NON",
                hubspotuserId: null,
                hubspotapiKey: null,
                converterkitApiKey: null,
                converterkitApiSecret: null,
                converterkitTagId: null,
                activeCampaignApiUrl: null,
                activeCampaignApiKey: null,
                intercomAccessToken: null,
                getResponseApiKey: null,
                getResponseCampaign: null,
                getResponseCampaignId: null,
                getResponsePhoneId: null,
                getResponseUrlId: null,
                getResponseScoreId: null,
                pipedriveApiToken: null,
                pipedriveUrlKey: null,
                pipedriveScoreKey: null,
                mailerliteApiKey: null,
                mailerliteGroupId: null,
                hubspotRefreshToken: null,
                zapierRefreshToken: null,
                infusionsoftApikey: null,
                infusionsoftApp: null,
                zapierConnection: "1",
                hubspotAllFields: null,
                mailerliteAllFields: null
            };

        },
        created() {
            let code = null;
            if (this.$route.query.code) {
                code = this.$route.query.code;
                this.clearQueryString();
            }
            this.$store.dispatch('integrations/getData')
                .then((response) => {
                    this.setIntegrationData(response);
                }).catch((error) => {
                this.showErrorNotifications(error);
            });
            if (code) {
                if (code.search('-') > -1) {
                    this.hubSpotAuthentication(code);
				} else {
                    this.mailChimpAuthentication(code);
				}

            }
        },
        methods: {
            showPopup(type) {
                this.revokeType = type;
                this.$bvModal.show('revoke-access-modal');
            },
            redirectToMailchimp() {
                window.location.href = this.mailChimpEndpoint;
            },
            redirectToHubSpot() {
                window.location.href = this.hubSpotEndpoint;
            },
            hubSpotAuthentication(code) {
                let sendData = {
                    code: code,
                    redirect_url: this.redirectUri
                };

                this.hubspotLoading = true;
                this.$store.dispatch('integrations/createHubSpot', sendData)
                    .then((response) => {
                        if (response.integrations) {
                            this.integration.hubspotRefreshToken = response.integrations.hubspot_refresh_token ?? '';
                        }
                        this.hubspotLoading = false;
                    })
                    .catch((error) => {
                        this.hubspotLoading = false;
                        this.showErrorNotifications(error);
                    });

            },
            mailChimpAuthentication(code) {
                this.$store.dispatch('integrations/createMailChimp', code)
                    .then((response) => {
                        if (response.length > 0) {
                            this.selectedOption = response[0]['value'];
                            this.key = "mailChimp" + this.selectedOption;
                            this.mailchimpOptions = response;
                            //rotate
                            this.$refs.mailchimp.flipBack();
                        }
                    })
                    .catch((error) => {
                        this.showErrorNotifications(error);
                    });
            },
            saveMailChimp() {
                if (this.selectedOption) {
                    this.$store.dispatch('integrations/getMailChimpAudience', this.selectedOption)
                        .then((response) => {
                            //update Integration object
                            this.setIntegrationData(response);
                            this.$refs.mailchimp.flipfront();
                        })
                        .catch((error) => {
                            this.showErrorNotifications(error);
                        });
                }
            },
            setIntegrationData(data) {
                if (data.integrations) {
                    this.integration.mailchimpEndPoint = data.integrations.mailchimp_end_point ?? null;
                    this.integration.mailchimpToken = data.integrations.mailchimp_token ?? null;
                    this.integration.mailchimpListId = data.integrations.mailchimp_list_id ?? '';
                    this.integration.hubspotRefreshToken = data.integrations.hubspot_refresh_token ?? '';
                }
                if (data.conversion_tracking) {
                    this.integration.analyticsScript = data.conversion_tracking.analytics_script ?? '';
                    this.integration.viewAnalytics = data.conversion_tracking.view_analytics ?? '';

                }
            },

            revokeAccess() {
                let sendData = {};

                let type = this.revokeType;

                switch (type) {
                    case 'MailChimp':
                        sendData.mailchimp_end_point = null;
                        sendData.mailchimp_token = '';
                        sendData.mailchimp_list_id = '';
                        break;
                    case 'HubSpot':
                        sendData.hubspot_refresh_token = null;
                        sendData.hubspot_all_fields = null;
                        break;
                    case 'Conversion Tracking':
                        sendData = {
                            analytics_script: this.integration.analyticsScript,
                            view_analytics: 0

						};
                        this.saveConversionTracking(sendData);
                        return;

                }

                this.$store.dispatch('integrations/updateData', sendData)
                    .then(() => {
                        switch (type) {
                            case 'MailChimp':
                                this.integration.mailchimpEndPoint =
                                    this.integration.mailchimpToken =
                                        this.integration.mailchimpListId = null;
                                break;
                            case 'HubSpot':
                                this.integration.hubspotRefreshToken = null;
                                break
                        }
                    })
                    .catch((error) => {
                        this.showErrorNotifications(error);
                    });
            },
            openConversionPopup() {
                this.analyticsScriptInput = this.integration.analyticsScript;
                this.scriptError = '';
                this.$bvModal.show('conversion-tracking-modal');
            },
            checkConversionTracking(evt) {
                evt.preventDefault();
                this.scriptError = '';
                    validate(this.analyticsScriptInput, 'script').then(result => {
                    if (result.valid) {
                        let sendData = {
                            analytics_script: this.analyticsScriptInput
						};
                        if (this.analyticsScriptInput) {
                            sendData.view_analytics = 1;
						} else {
                            sendData.view_analytics = 0;
						}

                        this.saveConversionTracking(sendData);
                        this.closeConversionPopup();
                    } else {
                        this.scriptError = result.errors[0];
                    }
                });

            },
            saveConversionTracking(sendData) {
                this.$store.dispatch('integrations/updateConversionTracking', sendData)
                    .then(() => {
                        this.integration.viewAnalytics = sendData.view_analytics;
                        this.integration.analyticsScript = sendData.analytics_script;

                    })
                    .catch((error) => {
                        this.showErrorNotifications(error);
                    });
			},
            closeConversionPopup() {
                this.$bvModal.hide('conversion-tracking-modal');
            },
            clearQueryString() {
                this.$router.replace({query: {}});
            }
        },
        computed: {
            mailChimpEndpoint() {
                return MAILCHIMP_ENDPOINT + this.mailchimpClientId + "&redirect_uri=" + this.redirectUri;
            },
            hubSpotEndpoint() {
                return HUBSPOT_ENDPOINT + this.hubSpotClientId + "&scope=oauth crm.objects.contacts.write crm.schemas.companies.write crm.schemas.contacts.write crm.schemas.deals.write&redirect_uri=" + this.redirectUri;
            },
            zapierEndpoint() {
                return process.env.VUE_APP_ZAPIER_INVITE_URL;
            },
            mailchimpClientId() {
                return process.env.VUE_APP_MAILCHIMP_CLIENT_ID;
            },
            hubSpotClientId() {
                return process.env.VUE_APP_HUBSPOT_CLIENT_ID;
            },
            redirectUri() {
                return window.location.origin + this.$route.path;
            }

        }
    }
</script>

<style lang="scss">
	@import "../../../../shared/assets/styles/blocks/integration";
</style>
<style>
	.card-link-info {
		font-size: 12px;
		line-height: 18px;
		color: #828F9C;
	}
</style>
