<template>
	<div class="flip-card" :class="{'flipped' : flipped}">
		<div class="flip-card-inner h-100">
			<div class="flip-card-front h-100">
				 <div class="card h-100">
					 <img class="card-img" :src="require('@shared/assets/styles/images/' + type + '.svg')" :alt="type">

					 <div class="card-body">
						 <h5 class="card-title">{{cardTitle}}</h5>
						 <p class="card-text">{{description}}</p>
					 </div>
					 <div class="card-footer">
						 <slot name="front"></slot>
					 </div>
				 </div>
			</div>
			<div class="flip-card-back h-100">
				<div class="card h-100">
					<img class="card-img" :src="require('@shared/assets/styles/images/' + type + '.svg')" :alt="type">

					<div class="card-body">
						<h5 class="card-title">{{cardTitle}}</h5>
					</div>
					<div class="card-footer">
						<slot name="back"></slot>
					</div>
				</div>
			</div>
		</div>
	</div>


</template>

<script>
    export default {
        name: "FlipCard",
        data() {
            return {
                flipped: false
            }
        },
        methods: {
            flipBack() {
                this.flipped = true;
            },
            flipfront() {
                this.flipped = false;
            },
            jsUcfirst(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        },
		computed: {
            cardTitle() {
                return this.title ? this.title : this.jsUcfirst(this.type)
			}
		},
        props: {
            type: {
                type: String,
                required: true
            },
			title: {
                type: String,
                required: false
            },
            description: {
                type: String,
                required: true
            }
        },
    }
</script>

<style scoped>
	.flip-card {
		height: 235px;
		perspective: 1000px;
	}

	.flip-card-inner {
		position: relative;
		text-align: center;
		transition: transform 0.8s;
		transform-style: preserve-3d;
	}

	.flip-card.flipped .flip-card-inner {
		transform: rotateY(180deg);

	}
	.flip-card.flipped .flip-card-front {
		display: none;
	}



	.flip-card-front, .flip-card-back {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}



	.flip-card-back {
		transform: rotateY(180deg);
	}
</style>
